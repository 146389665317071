body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
/* ul 리스트 스타일 초기화 */
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* 카테고리 목록 스타일 */
.widget_content li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 16px;
}

/* 카테고리 링크 스타일 */
.widget_content a {
  color: black;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

/* 마우스 오버 시 링크 색상 변경 */
.widget_content a:hover {
  color: red;
}

/* 링크 클릭 시 색상 변경 */
.widget_content a:active {
  color: black;
}

/* 카테고리 수량 스타일 */
.widget_content .count {
  color: #999;
  font-size: 14px;
}

/* 활성화된 링크 스타일 */
.widget_content .active a {
  color: black;
  font-weight: bold;
}
