.App {
  text-align: center;
}

.card {
  width: calc(20vw - 100px);
  height: 40vh;
  perspective: 1000px;
  position: absolute;
  transition: top 0.5s, right 0.5s, transform 0.5s;
}

.card-inner {
  width: 100%;
  height: 0;
  padding-bottom: 141.4%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.card-front {
  background: url("./img/cardback.jpg") center/contain;
  background-repeat: no-repeat;
  transform: rotateY(0deg);
  border-radius: 10px;
}

.card-back {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  transform: rotateY(180deg);
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #00000066;
  z-index: 99;
}

.mainCard {
  width: 60vh;
  height: 84vh;
  position: absolute;
  top: 10%;
  right: 30%;
  z-index: 100;
}

.wh {
  width: 100%;
  height: 100%;
}
.card01 {
  background: url("./img/card01.jpg") center/contain;
  background-repeat: no-repeat;
}
.card02 {
  background: url("./img/card02.jpg") center/contain;
  background-repeat: no-repeat;
}
.card03 {
  background: url("./img/card03.jpg") center/contain;
  background-repeat: no-repeat;
}
.card04 {
  background: url("./img/card04.jpg") center/contain;
  background-repeat: no-repeat;
}
.card05 {
  background: url("./img/card05.jpg") center/contain;
  background-repeat: no-repeat;
}
.card06 {
  background: url("./img/card06.jpg") center/contain;
  background-repeat: no-repeat;
}
.card07 {
  background: url("./img/card07.jpg") center/contain;
  background-repeat: no-repeat;
}
.card08 {
  background: url("./img/card08.jpg") center/contain;
  background-repeat: no-repeat;
}
.card09 {
  background: url("./img/card09.jpg") center/contain;
  background-repeat: no-repeat;
}
.card10 {
  background: url("./img/card10.jpg") center/contain;
  background-repeat: no-repeat;
}
.card11 {
  background: url("./img/card11.jpg") center/contain;
  background-repeat: no-repeat;
}
.card12 {
  background: url("./img/card12.jpg") center/contain;
  background-repeat: no-repeat;
}
.card13 {
  background: url("./img/card13.jpg") center/contain;
  background-repeat: no-repeat;
}
.card14 {
  background: url("./img/card14.jpg") center/contain;
  background-repeat: no-repeat;
}
.card15 {
  background: url("./img/card15.jpg") center/contain;
  background-repeat: no-repeat;
}
.card16 {
  background: url("./img/card16.jpg") center/contain;
  background-repeat: no-repeat;
}
.card17 {
  background: url("./img/card17.jpg") center/contain;
  background-repeat: no-repeat;
}
.card18 {
  background: url("./img/card18.jpg") center/contain;
  background-repeat: no-repeat;
}
.card19 {
  background: url("./img/card19.jpg") center/contain;
  background-repeat: no-repeat;
}
.card20 {
  background: url("./img/card20.jpg") center/contain;
  background-repeat: no-repeat;
}
.card21 {
  background: url("./img/card21.jpg") center/contain;
  background-repeat: no-repeat;
}
.card22 {
  background: url("./img/card22.jpg") center/contain;
  background-repeat: no-repeat;
}
.card23 {
  background: url("./img/card23.jpg") center/contain;
  background-repeat: no-repeat;
}
.card24 {
  background: url("./img/card24.jpg") center/contain;
  background-repeat: no-repeat;
}
.card25 {
  background: url("./img/card25.jpg") center/contain;
  background-repeat: no-repeat;
}
.card26 {
  background: url("./img/card26.jpg") center/contain;
  background-repeat: no-repeat;
}
.card27 {
  background: url("./img/card27.jpg") center/contain;
  background-repeat: no-repeat;
}
.card28 {
  background: url("./img/card28.jpg") center/contain;
  background-repeat: no-repeat;
}
.card29 {
  background: url("./img/card29.jpg") center/contain;
  background-repeat: no-repeat;
}
.card30 {
  background: url("./img/card30.jpg") center/contain;
  background-repeat: no-repeat;
}
.card31 {
  background: url("./img/card31.jpg") center/contain;
  background-repeat: no-repeat;
}
.card32 {
  background: url("./img/card32.jpg") center/contain;
  background-repeat: no-repeat;
}
.card33 {
  background: url("./img/card33.jpg") center/contain;
  background-repeat: no-repeat;
}
.card34 {
  background: url("./img/card34.jpg") center/contain;
  background-repeat: no-repeat;
}
.card35 {
  background: url("./img/card35.jpg") center/contain;
  background-repeat: no-repeat;
}
.card36 {
  background: url("./img/card36.jpg") center/contain;
  background-repeat: no-repeat;
}
.card37 {
  background: url("./img/card37.jpg") center/contain;
  background-repeat: no-repeat;
}
.card38 {
  background: url("./img/card38.jpg") center/contain;
  background-repeat: no-repeat;
}
.card39 {
  background: url("./img/card39.jpg") center/contain;
  background-repeat: no-repeat;
}
.card40 {
  background: url("./img/card40.jpg") center/contain;
  background-repeat: no-repeat;
}
.card41 {
  background: url("./img/card41.jpg") center/contain;
  background-repeat: no-repeat;
}
.card42 {
  background: url("./img/card42.jpg") center/contain;
  background-repeat: no-repeat;
}
.card43 {
  background: url("./img/card43.jpg") center/contain;
  background-repeat: no-repeat;
}
.card44 {
  background: url("./img/card44.jpg") center/contain;
  background-repeat: no-repeat;
}
.card45 {
  background: url("./img/card45.jpg") center/contain;
  background-repeat: no-repeat;
}
.card46 {
  background: url("./img/card46.jpg") center/contain;
  background-repeat: no-repeat;
}
.card47 {
  background: url("./img/card47.jpg") center/contain;
  background-repeat: no-repeat;
}
.card48 {
  background: url("./img/card48.jpg") center/contain;
  background-repeat: no-repeat;
}
.card49 {
  background: url("./img/card49.jpg") center/contain;
  background-repeat: no-repeat;
}
.card50 {
  background: url("./img/card50.jpg") center/contain;
  background-repeat: no-repeat;
}
.card51 {
  background: url("./img/card51.jpg") center/contain;
  background-repeat: no-repeat;
}
.card52 {
  background: url("./img/card52.jpg") center/contain;
  background-repeat: no-repeat;
}

.left {
  grid-area: left;
}

.right {
  grid-area: right;
}

.c {
  grid-area: c;
}

.d {
  grid-area: d;
}

.e {
  grid-area: e;
}

.f {
  grid-area: f;
}

.g {
  grid-area: g;
}

.h {
  grid-area: h;
}

.i {
  grid-area: i;
}

.j {
  grid-area: j;
}

.inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}
.flipped .inner {
  transform: rotateY(180deg);
}

.front,
.back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.front {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  transform: rotateY(180deg);
}

.back {
  background: url("./img/cardback.jpg") center/contain;
  background-repeat: no-repeat;

  transform: rotateY(0deg);
  background-repeat: no-repeat;
}
.bsc {
  background-size: cover !important;
}
.twoFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.map-container {
  width: 70vw;
  height: 46.2vw;
  border: 1px solid black;
  position: relative;
  background-image: url("./img/map01.jpg"); /* public 폴더에 있는 이미지를 사용 */
  background-size: cover; /* 배경 이미지 크기를 맵 크기에 맞춤 */
  background-position: center; /* 배경 이미지 위치를 중앙으로 설정 */
}
.gameInput {
  width: 15px;
  border-radius: 7px;
  position: absolute;
  transform: translate(-50%, -50%);
}
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f7f7f7;
}

.layout {
  display: flex;
}

.left-sidebar {
  width: 200px;
  padding: 10px;
}

.center-content {
  flex: 1;
  padding: 10px 10px 10px 50px;
}
.buttonA {
  width: 200px;
  height: 50px;
  border-radius: 30px;
  background: linear-gradient(to bottom right, #7d9fa9, #dbc2c5);
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  margin: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  border: none;
  font-weight: bold;
}
.ac {
  text-align: center;
}
table {
  width: 800px;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid #ddd;
  padding: 12px 4px;
}
